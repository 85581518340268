/* Add underlining for the active link in navigation tabs and pills */
.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}gray-700);
      font-weight: $fw-medium;

      &.active {
        color: $primary;
        border-bottom: 2px solid $primary; /* Add a border bottom for underlining */
      }
    }
  }
}

/* Style for custom navigation tabs */
.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}gray-300);

  .nav-item {
    position: relative;
    color: var(--#{$prefix}dark);

    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

/* Style for vertical navigation */
.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      
      .nav-icon {
        font-size: 24px;
      }

      &.active {
        background-color: $primary;
        color: white; /* Adjust text color for active state */
      }
    }
  }
}

.nav-font-size{
  font-size: 16px !important;
}