.nav-item.dropdown .nav-link.txtcolor,
.nav-item.dropdown .nav-link.txtcolor>i,
.nav-item.dropdown .nav-link.txtcolor>p {
    color: #ffff !important;
}



@media (max-width: 767px) {

    .nav-item.dropdown .nav-link.txtcolor,
    .nav-item.dropdown .nav-link.txtcolor>i,
    .nav-item.dropdown .nav-link.txtcolor>p {
        color: #6f6f6f !important;
    }
}